import { useCallback, useContext, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
// import cn from 'classnames';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import ActiveLink from './common/ActiveLink';
import { LanguageContext } from './LanguageProvider';
import { Button } from '@mui/material';

const Layout = () => {
  const { changeLanguage } = useContext(LanguageContext);
  const { user, logout } = useAuth0();

  const onSignOutClick = useCallback(
    () =>
      logout({
        returnTo: window.location.origin,
      }),
    [logout]
  );

  useEffect(() => {
    // Set default language to english
    changeLanguage('en');
  }, [changeLanguage]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
      }}
    >
      <header>
        <Link to="/">
          <span className="logo">Supertone Studio</span>
          {process.env.REACT_APP_ENV !== 'prd' && (
            <span style={{ color: 'orange', fontWeight: 'bold' }}>DEV</span>
          )}
        </Link>
        <nav>
          <ul>
            <li>
              <ActiveLink to="/mss">MSS&SE</ActiveLink>
            </li>
            <li>
              <ActiveLink to="/svs">SVS</ActiveLink>
            </li>
            <li>
              <ActiveLink to="/tts">TTS</ActiveLink>
            </li>
            <li>
              <ActiveLink to="/bwe">BWE</ActiveLink>
            </li>
            <li>
              <ActiveLink to="/tuner">Tuner</ActiveLink>
            </li>
          </ul>
        </nav>

        <div className="user">
          <div className="email">{user?.email}</div>
          <Button
            variant="outlined"
            color="inherit"
            size="small"
            onClick={!!user ? onSignOutClick : undefined}
            sx={{
              color: '#000',
              fontSize: '0.7rem',
              padding: '0rem 0.5rem',
              visibility: !!user ? 'visible' : 'hidden',
            }}
          >
            Sign out
          </Button>
        </div>
      </header>

      <Outlet />
    </div>
  );
};

export default Layout;
