import { useCallback, useEffect, useState } from 'react';
import { FileWithPath } from 'react-dropzone';
import toast from 'react-hot-toast';
import { FormattedMessage } from 'react-intl';
import { FormControl, MenuItem, Select, Typography } from '@mui/material';
import { UploadFile as UploadFileIcon } from '@mui/icons-material';
import Area from '../common/area-grid/Area';
import AreaGrid from '../common/area-grid/AreaGrid';
import DropzoneContainer from '../common/DropzoneContainer';
import Row from '../common/area-grid/Row';
import DeleteIcon from '../assets/icons/delete.svg';
import { formatReportData, sendPostRequest, sendReport } from '../util/api';
import cuid from 'cuid';
import AudioCard from '../common/AudioCard';
import ProcessingButtons from '../components/ProcessingButtons';
import ErrorToast from '../common/ErrorToast';
import { MAX_FILE_SIZE } from '../consts';
import ReportButton, { ReportData } from '../common/ReportButton';
import { AudioData } from '../common/types';

interface AudioResultGroup {
  id: string;
  date: Date;
  text: string;
  results: AudioResult[];
}

interface AudioResult {
  id: string;
  audioData: Blob;
}

type ModelType = 'v1.0.0';

type ModelInfo = {
  label: string;
};

type BWEParameter = {
  api: string;
  model_type: ModelType;
  src: FileWithPath;
};

const modelInfoMap: Record<ModelType, ModelInfo> = {
  'v1.0.0': {
    label: 'v1.0.0',
  },
};

const modelTypes = Object.keys(modelInfoMap) as ModelType[];

const API_PATH = process.env.REACT_APP_API_PATH_BWE!;

const BwePage = () => {
  const [selectedModelType, setSelectedModelType] = useState<ModelType>(
    modelTypes[0]
  );
  const [audioData, setAudioData] = useState<AudioData | null>(null);
  const [results, setResults] = useState<AudioResultGroup[]>([]);

  // 결과 처리 중 여부
  const [isProcessing, setIsProcessing] = useState(false);

  // 오류 발생 여부
  const [isError, setIsError] = useState(false);

  const [abortController, setAbortController] =
    useState<AbortController | null>(null);

  const onAudioSelect = async (files: FileWithPath[]) => {
    const file = files[0];

    setAudioData({
      file: new File([file], file.name, { type: 'audio/wav' }),
      type: 'file',
      name: file.name,
    });
  };

  useEffect(() => {
    if (audioData) {
      const url = URL.createObjectURL(audioData.file);
      const player = document.getElementById(
        'audio-player'
      ) as HTMLAudioElement;

      // 오디오 플레이어 설정
      if (player) player.src = url;
    }
  }, [audioData]);

  const onAudioDelete = () => {
    setAudioData(null);
  };

  const getRequestData = () => {
    if (!audioData) return;

    const data = {
      api: 'bwe',
      model_type: selectedModelType,
      src: audioData.file,
    } as BWEParameter;

    return data;
  };

  const onSubmit = async () => {
    if (!audioData) {
      toast.error('오디오를 선택해주세요.');
      return;
    }

    setIsProcessing(true);

    try {
      const requestData = getRequestData();
      const abortController = new AbortController();
      setAbortController(abortController);

      const { data } = await sendPostRequest<typeof requestData>(
        API_PATH,
        requestData,
        {
          'Content-Type': 'multipart/form-data',
        },
        {
          responseType: 'arraybuffer',
        },
        abortController
      );

      setIsError(false);

      const result: AudioResult = {
        id: cuid(),
        audioData: new Blob([data], { type: 'audio/wav' }),
      };

      const group: AudioResultGroup = {
        id: cuid(),
        date: new Date(),
        text: 'Result',
        results: [result],
      };

      setResults([group]); // 단일 결과로 설정
    } catch (e: any) {
      if (e?.code === 'ERR_CANCELED') {
        return e.code;
      }
      setIsError(true);
    } finally {
      setIsProcessing(false);
    }
  };

  // 결과 삭제 시
  const onResultDelete = (groupId: string, id: string) => () => {
    const group = results.find((group) => group.id === groupId);

    if (!group) return;

    const index = group.results.findIndex((result) => result.id === id);
    group.results.splice(index, 1);

    setResults([...results]);
  };

  const abort = useCallback(() => {
    abortController?.abort();
  }, [abortController]);

  const handleReportSubmit = async ({ user, problem }: ReportData) => {
    if (!selectedModelType) return;
    if (!audioData) return;

    const inputData = await getRequestData();
    if (!inputData) return;

    // Need to send audio data as array.
    // @ts-expect-error
    inputData.tgt = [audioData];

    const data = formatReportData({
      ...inputData,
      user,
      problem,
    });

    const r = await sendReport(data);
    return r;
  };

  return (
    <AreaGrid className="bwe">
      <Row>
        <Area
          name="main"
          size="380px"
          minSize="380px"
          maxSize="50%"
          splitterAt="left"
        >
          <div>
            <div
              className="contents"
              style={{
                height: `calc(100% - ${isProcessing ? 120 : 70}px)`,
                boxShadow: 'none',
                paddingRight: '1.5rem',
              }}
            >
              <section className="description">
                <Typography variant="h2" component="h2">
                  BWE
                </Typography>
              </section>
              <div>
                <section>
                  <FormControl fullWidth>
                    <div className="inner">
                      <div className="title">Model Type</div>
                    </div>
                    <Select
                      className="tts-select"
                      value={selectedModelType}
                      onChange={(e) =>
                        setSelectedModelType(e.target.value as ModelType)
                      }
                      fullWidth={true}
                      label=""
                      sx={{
                        marginTop: '0.5em',
                        backgroundColor: '#fff',
                      }}
                    >
                      {modelTypes.map((model) => {
                        return (
                          <MenuItem key={model} value={model}>
                            {modelInfoMap[model].label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </section>

                <section>
                  <div className="inner">
                    <div className="title">Source audio</div>
                    <Typography variant="subtitle1">
                      Only mono audio channels are supported.
                    </Typography>
                  </div>
                  {audioData && (
                    <div className="audio-container">
                      <span>{audioData.name}</span>
                      <button className="btn-delete" onClick={onAudioDelete}>
                        <img src={DeleteIcon} alt="Delete source audio." />
                      </button>
                      <audio
                        controls
                        id="audio-player"
                        title={audioData.name}
                      />
                    </div>
                  )}
                  <DropzoneContainer
                    successCallback={onAudioSelect}
                    maxSize={MAX_FILE_SIZE}
                    accept={{
                      'audio/*': [],
                    }}
                  >
                    <p>
                      <UploadFileIcon fontSize="small" />
                      <FormattedMessage
                        id="dropzone"
                        defaultMessage="Drop files or click here"
                      />
                    </p>
                  </DropzoneContainer>
                </section>
              </div>
            </div>
            <div
              style={{
                padding: '1em',
                boxSizing: 'border-box',
              }}
            >
              <ProcessingButtons
                isDisabled={false}
                onAbort={abort}
                onSubmit={onSubmit}
                isProcessing={isProcessing}
              />
            </div>
          </div>
        </Area>

        <Area name="contents-container">
          <AreaGrid className="contents-container">
            <Row>
              <Area name="contents">
                <div className="main">
                  <section>
                    <ErrorToast
                      isOpen={isError}
                      updateIsOpen={(status: boolean) => setIsError(status)}
                    />
                  </section>

                  <section
                    className="results-container"
                    style={{ paddingBottom: '2rem' }}
                  >
                    {results.map((group) => {
                      const audioTitle = group.text
                        .replace(/\s/gim, '')
                        .slice(0, 18);

                      return (
                        <div
                          key={group.id}
                          className="result-group"
                          style={{
                            padding: '1rem',
                          }}
                        >
                          <h3 className="text" style={{ color: '#000' }}>
                            {group.text}
                          </h3>
                          {group.results.map((r) => (
                            <AudioCard
                              key={r.id}
                              title={group.text}
                              onDelete={onResultDelete(group.id, r.id)}
                              audioId="result-player"
                              audioTitle={audioTitle}
                              audioUrl={r.audioData}
                            ></AudioCard>
                          ))}
                        </div>
                      );
                    })}
                  </section>

                  <ReportButton submitCallback={handleReportSubmit} />
                </div>
              </Area>
            </Row>
          </AreaGrid>
        </Area>
      </Row>
    </AreaGrid>
  );
};

export default BwePage;
